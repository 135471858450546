import './App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

import tr from './assets/tr.jpg';
import en from "./assets/en.png";
import ger from "./assets/ger.png";
import Card from './components/Card';
import { useState } from 'react';

function App() {
  const [lang, setLang] = useState('ger');

  
  
  let images = [
    '1.jpeg',
    '2.jpeg',
    '3.jpg',
    '4.jpeg',
    '5.jpeg'
  ];

  return (
    <>
    <div className='bg-image'></div>
    <div className='shadow'></div>
      <div className='hero'>
        <div className='content'>
          <p className='address'>Henriettenstr 6<br/>
          73230 Kirchheimteck <br/>
            Deutschland</p>
            <div></div>
          <div>
            <div className='lang'>
              <div style={{backgroundImage: `url(${tr})`}} onClick={() => setLang('tr')}>
              </div>
              <div style={{backgroundImage: `url(${en})`}} onClick={() => setLang('en')}>
              </div>
              <div style={{backgroundImage: `url(${ger})`}} onClick={() => setLang('ger')}>
              </div>
            </div>
          <header>
            <h1>VIP Lounge</h1>
            {lang === 'ger' ? <h3>Öffnungszeiten</h3> : null}
            {lang === 'tr' ? <h3>Açılış Saatleri</h3> : null}
            {lang === 'en' ? <h3>Opening Hours</h3> : null}
            <div className='schedule'>
              <div>
                {lang === 'ger' ? <p>Montag</p> : null}
                {lang === 'tr' ? <p>Pazartesi</p> : null}
                {lang === 'en' ? <p>Monday</p> : null}
                {lang === 'ger' ? <p>Di.-Do.</p> : null}
                {lang === 'tr' ? <p>Salı-Perş.</p> : null}
                {lang === 'en' ? <p>Tue.-Thur.</p> : null}
                {lang === 'ger' ? <p>Fr.-Sa.</p> : null}
                {lang === 'tr' ? <p>Cuma-Cts.</p> : null}
                {lang === 'en' ? <p>Fr.-Sat.</p> : null}
                {lang === 'ger' ? <p>Sonntag</p> : null}
                {lang === 'tr' ? <p>Pazar</p> : null}
                {lang === 'en' ? <p>Sunday</p> : null}
              </div>
              <div>
                {lang === 'ger' ? <p>Ruhetag</p> : null}
                {lang === 'tr' ? <p>Kapalı</p> : null}
                {lang === 'en' ? <p>Day Off</p> : null}
                {lang === 'ger' ? <p>16-01 Uhr</p> : null}
                {lang === 'tr' ? <p>16:00-01:00</p> : null}
                {lang === 'en' ? <p>04:00 AM-01:00 PM</p> : null}
                {lang === 'ger' ? <p>16-03 Uhr</p> : null}
                {lang === 'tr' ? <p>16:00-03:00</p> : null}
                {lang === 'en' ? <p>04:00 AM-03:00 PM</p> : null}
                {lang === 'ger' ? <p>16-01 Uhr</p> : null}
                {lang === 'tr' ? <p>16:00-01:00</p> : null}
                {lang === 'en' ? <p>04:00 AM-01:00 PM</p> : null}
              </div>
            </div>
          </header>
          </div>
          <div className='contact'>
            <div>
              <a href='mailto:viplounge20@hotmail.com' target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faEnvelope} className='icon' />
              </a>
              <p>viplounge20@hotmail.com</p>
            </div>
            <div>
              <a href='tel:+49-1767-0140551' target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faPhone} className='icon' />
              </a>
              <p>049176 70140551</p>
            </div>
            <div>
              <a href='https://www.instagram.com/vip_lounge_kirchheim_teck?igsh=MTkxeXk5NzZ2NDlidQ%3D%3D' target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faInstagram} className='icon' />
              </a>
              <p>vip_lounge_kirchheim_teck</p>
            </div>
          </div>
        </div>
      </div>

      <div className='comments'>
        <h2>Comments</h2>
        <div className='cards'>
          <div className='col'>
            <Card author={'Manuel Jäkel'} content={lang === 'ger' ? 'Sehr gutes Team Preise sind fair.' : lang === 'tr' ? 'Çok iyi bir ekip. Fiyatlar makul.' : 'Very good team. Prices are fair.'} />
            <Card author={'Aleyna i'} content={lang === 'ger' ? 'Gute Atmosphäre, schönes Ambiente' : lang === 'tr' ? 'İyi bir atmosfer, güzel bir ortam' : 'Good atmosphere, nice ambience'} />
          </div>
          <div className='col'>
            <Card author={'Lucas M'} content={lang === 'ger' ? 'Sehr aufmerksames Team ' : lang === 'tr' ? 'Çok dikkatli bir ekip' : 'Very attentive team'} />
            <Card author={'Tabea J'} content={lang === 'ger' ? 'Sehr leckere Cocktails' : lang === 'tr' ? 'Çok lezzetli kokteyller' : 'Very tasty cocktails'} />
          </div>
          <div className='col'>
            <Card author={'Michael H'} content={lang === 'ger' ? 'In vollem und ganzen alles super. Weiter so' : lang === 'tr' ? 'Genel olarak her şey harikaydı. Aynen böyle devam' : 'Overall everything was great. Keep it up'} />
            <Card author={'Jürgen D'} content={lang === 'ger' ? 'Sehr schöne Stimmung. Gemischtes Publikum, macht Spaß' : lang === 'tr' ? 'Çok hoş bir atmosfer. Karışık seyirci, eğlence' : 'Very nice atmosphere. Mixed audience, fun'} />
          </div>
        </div>
      </div>

      <div className='gallery col'>
        <h2>Gallery</h2>
        <div className='pictures'>
          {images.map((item, i) => {
            return <img key={i} src={require('./assets/' + item)} alt={item} />
          })}
        </div>
      </div>

      <div id='map' className='map col'>
        <iframe title='map-location' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2636.122746953933!2d9.443227076452164!3d48.6457669713041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDjCsDM4JzQ0LjgiTiA5wrAyNic0NC45IkU!5e0!3m2!1sen!2str!4v1709287629067!5m2!1sen!2str" style={{border: '0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      <footer>
        <div style={{display: 'flex', gap: '10px'}}>
        <h4>VIP Lounge</h4>
        <p>&copy; All Rights Reserved</p>
        </div>
          <p style={{fontStyle: 'italic', color: 'white'}}>by <span style={{color: 'blueviolet'}}>WetaTech</span></p>
      </footer>
    </>
  );
}

export default App;
